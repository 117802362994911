import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { jsPDF } from 'jspdf'
import { OnlinePaymentService } from 'src/app/services/online-payment.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MyCartService } from 'src/app/services/my-cart.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { filter } from 'rxjs/operators';
import html2canvas from 'html2canvas';



@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  public user;
  public userTransactionDetails;
  public billingAddress;
  public userDetails;
  public transactionData: any = [];
  public isShowDiv = false;
  public serialNo: any;
  public invoiceNumber;
  public invoiceYear;
  public values;
  public disabled = false;
  public coursesPurchased = [];
  @ViewChild('invoicecontent', { static: false }) content: ElementRef;

  constructor(public userservice: UserService, public route: ActivatedRoute, public transaction: OnlinePaymentService,
              private router: Router) {

    this.userDetails = this.userservice.userDetails.data;
    this.transaction.getTransactionDetails(this.userDetails.userId).subscribe(data => {
      data.transactionDetails.forEach(ele => {
        this.transactionData.push(ele);
      });
    });

  }


  public convertToPDF() {
    // const pdf = new jsPDF('p', 'pt', 'a4');
    // pdf.html(this.content.nativeElement, {
    //   callback: (pdf) => {
    //     pdf.save('smartgcpro_invoice_' + this.invoiceNumber);
    //   }
    // });
    // const data = document.getElementById('contentToConvert');
    html2canvas(this.content.nativeElement, {
      allowTaint: true,
      useCORS: true,
      scale: 1
    }).then(canvas => {
      // Few necessary setting options
      const imgWidth = 210;
      const pageHeight = 100;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;

      const contentDataUrl = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataUrl, 'png', 0, position, 208, imgHeight);
      pdf.save('smartgcpro_invoice_' + this.invoiceNumber + '.pdf'); // Generated PDF

    });
  }





  ngOnInit(): void {

    this.route.queryParams
    .pipe(
      filter(params => params.transactionId)
    )
    .subscribe(params => {
      this.transaction.getTransaction(params.transactionId).subscribe(data => {
        this.userTransactionDetails = data.transactionDetail[0];
        this.invoiceYear = this.userTransactionDetails.transactionDate.slice(7, 11);
        this.invoiceNumber = 'S' + this.userTransactionDetails.userTransactionId;
        this.coursesPurchased = this.userTransactionDetails.coursesPurchased;
      });
    });
  }

}
