import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { UserService } from 'src/app/services/user.service';
import { MyCartService } from 'src/app/services/my-cart.service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { OnlinePaymentService } from 'src/app/services/online-payment.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserProgressService } from 'src/app/services/user-progress.service';
import { SharedserviceService } from 'src/app/services/SharedService/sharedservice.service';

@Component({
  selector: 'app-dashbord-course-details',
  templateUrl: './dashbord-course-details.component.html',
  styleUrls: ['./dashbord-course-details.component.scss'],
})
export class DashbordCourseDetailsComponent implements OnInit {
  public userName;
  public cart = false;
  public isShowDiv = false;
  public firstLetter;
  public cartBadge;
  public userDetails;
  public categoryDetails = [];
  public transactionDetails = [];
  public cartDetails;
  public wishlistUser;
  public disabled = false;
  public hide = true;
  public courseId;
  public percentage;
  public values;
  public CoursecompletedTime;
  public chatbot;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  value = 50;
  sub: Subscription;
  CourseDetails: any = [];
  selectedCouserByUser;
  selectedScreen;
  startOverBtn = true;
  retake = true;
  public selectcoursess;

  constructor(
    public service: CommonServiceService,
    public userservice: UserService,
    public route: ActivatedRoute,
    public sharedService: SharedserviceService,
    public transaction: OnlinePaymentService,
    public userProgress: UserProgressService,
    public router: Router
  ) {
    this.courseId = this.route.snapshot.params.usercourse;
    this.selectcoursess = this.route.snapshot.params.selectedCourse;
  }

  ngOnInit(): void {
    document.getElementById('scrollingg').scrollIntoView(true);

    console.log(this.sharedService.courseDetails, 'sharedServicecourseDetails');
    console.log(this.selectcoursess, 'dashboardcourse');
    // window.scrollTo({ top: 0 });
    if (this.service.sharedData === undefined) {
      this.router.navigate(['/layout/dashboard']);
    }
    if (this.courseId) {
      this.userProgress.getCourse(this.courseId, this.userservice.userDetails.data.userId).subscribe((x) => {
        // document.body.scrollTop = 0;

        // console.log(this.courseId, "i am in courseId")

        // console.log(x, "i am in x")
        this.percentage = parseFloat(x.courseCompletedPercentage.toFixed());
        this.CourseDetails = x.userData;
        this.CoursecompletedTime = x.completedTime;
        //  console.log( this.CourseDetails,"coursedata")
      });
    }

    // console.log( this.CourseDetails,"coursedata1")
  }

  ngAfterViewInit() {
    // document.body.scrollTop = 0;

    window.scrollTo({ top: 0 });
    document.documentElement.scrollTo(0, 0); // For Firefox
  }

  clickCard(selectedCourse) {
    this.selectedCouserByUser = selectedCourse;
    this.selectedScreen = false;
    this.service.sendMessage(false);
    const data = {
      data: selectedCourse,
    };
    this.router.navigate(['/course/course-videos', { usercourse: selectedCourse.userCourseId }], (this.service.sharedData = selectedCourse));
  }

  startOver(selectedCourse) {
    this.selectedCouserByUser = selectedCourse;
    this.selectedScreen = false;
    this.service.sendMessage(false);
    const data = {
      data: selectedCourse,
    };
    this.router.navigate(['/course/course-videos', { usercourse: selectedCourse.userCourseId, start: this.startOverBtn }]);
    // this.startOverBtn=true;
  }

  resume(selectedCourse) {
    this.selectedCouserByUser = selectedCourse;
    this.selectedScreen = false;
    this.service.sendMessage(true);
    const data = {
      data: selectedCourse,
    };
    this.router.navigate(['/course/course-videos', { usercourse: selectedCourse.userCourseId }]);
  }

  retakeCourse(selectedCourse) {
    this.selectedCouserByUser = selectedCourse;
    this.selectedScreen = false;
    this.service.sendMessage(true);
    const data = {
      data: selectedCourse,
    };
    this.router.navigate(['/course/course-videos', { usercourse: selectedCourse.userCourseId, reTake: this.retake }]);
  }
}
