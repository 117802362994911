<section class="header-section bg-background-dark p-4">
  <div class="container-fluid container-lg">
    <h1 class="mat-title text-light my-2">My Learnings</h1>
  </div>
</section>

<section class="dashboard-search-section">
  <div class="example-sidenav-content container-fluid container-lg pt-2">
    <div class="search-form w-100">
      <div class="search-form-input-group text-light">
        <!-- <label class="mat-caption">Search courses</label> -->
        <input class="mat-body text-light course-input" id="searchvalue" type="text" placeholder="Search your enrollments"
          [(ngModel)]="search">
        <button mat-icon-button class="text-light-secondary">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </div>
</section>

<section class="loader-section py-5 d-flex justify-content-center align-items-center" *ngIf="!isCourseLoaded">
  <mat-spinner color="accent"></mat-spinner>
</section>

<section class="courses-section" *ngIf="isCourseLoaded">
  <div class="container-fluid container-lg pb-4">
    <div class="row">
      <ng-container *ngIf="( courses | filter : search) as result">
        <div class="col-sm-4 col-md-3 mt-3" *ngFor="let course of result ">
          <div class="course-card" (click)="!course.expired ? clickCard(course) : courseExpired(course)">
            <div class="course-card-img-container">
              <div class="course-card-btn-container d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12" fill="rgba(0,0,0,0.35)" />
                  <path d="M9 6.6v10.643l8.362-5.321z" fill="#fff" />
                </svg>
                <!-- <button mat-stroked-button class="border-medium border-accent bg-dark-disabled">View Details</button> -->
              </div>

              <img class="course-card-img mb-0" src="{{course.thumbnailImage}}" alt="{{course.courseName}}">
            </div>
            <div class="p-3">
              <p class="course-card-name mat-body text-light mb-1 mt-2">{{course.courseName}}</p>
              <p class="course-card-desc mb-1 text-justify">{{course.newDesc}}</p>
              <mat-progress-bar class="course-card-progress mt-2"
                [ngClass]="{'accent': course.percentageCompletion < 25,'blue': course.percentageCompletion >= 25 && percentage <= 99, 'green': course.percentageCompletion >=100 && course.courseStatus==='COMPLETED'}"
                mode="determinate" [value]="course.percentageCompletion"></mat-progress-bar>
              <p class="course-card-others mb-1 text-justify"
                *ngIf="!course.expired && course.percentageCompletion > 0">{{course.percentageCompletion | number:
                '1.0-0'}}% Completed</p>
              <p class="course-card-others mb-1 text-justify"
                *ngIf="!course.expired && (!course.percentageCompletion || course.percentageCompletion <= 0)">START
                COURSE</p>
              <p class="course-card-others mb-1 text-justify text-accent" *ngIf="course.expired">(EXPIRED)</p>
            </div>
          </div>
        </div>
        <ng-container *ngIf="result.length === 0">
          <div class="text-center no-courses mt-5">
            <h3 class="text-light-disabled" >No items found</h3>
            <button mat-stroked-button color="accent" (click)="viewCourses()">Enroll Now</button>
          </div>
        </ng-container>
      </ng-container>
      
    </div>

    <!-- <div class="no-courses text-center mt-5" *ngIf="courses.length===0 && noCourse">
      <h2 class="text-dark">You have not yet enrolled the course</h2>
      <button mat-button class="view-courses" (click)="viewCourses()">
        <mat-icon>search</mat-icon>&nbsp;&nbsp;
        View all Courses
      </button>
    </div> -->
  </div>
</section>