<mat-sidenav-container>
  <mat-sidenav-content>
    <div class="flex-grow-1 flex-md-grow-0 text-center text-md-left p-4">
      <a class="text-center" href="#">
        <!-- <img class="nav-logo" src="../../../assets/images/smartgdpro_logo.svg"> -->
        <img src="../../assets/smartgclogoblue.png" alt="SMARTGCPRO" class="nav-logo" />
      </a>
    </div>
    <section class="hero-section">
      <div class="container-fluid container-lg lg-device">
        <div class="mainDiv">
          <div class="row">
            <div class="col-lg-12 col-md-12 mt-5">
              <form [formGroup]="emailUserForm">
                <div class="form-layout">
                  <h1 class="text-white tet-color"><b>RESET PASSWORD</b></h1>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field appearance="standard">
                        <mat-label>New Password</mat-label>
                        <input matInput formControlName="newPassword" matTooltipClass="test" [type]="hide ? 'password' : 'text'" required />
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" tabindex="-1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                        </button>
                        <mat-hint>Password policy : Password must be atleast 8 character in length.Atleast 1 uppercase (A-Z).Atleast 1 lowercase (a-z).Atleast 1 number (0-9).Atleast 1 special characters (eg : !@#$%^&*)."</mat-hint>
                        <mat-error *ngIf="emailUserForm.controls.newPassword.invalid"> Please enter a valid password </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 mt-5">
                      <mat-form-field appearance="standard">
                        <mat-label>Confirm New Password</mat-label>
                        <input matInput formControlName="confirmNewPassword" [type]="hideConfirm ? 'password' : 'text'" required />
                        <button type="button" mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" tabindex="-2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                          <mat-icon>{{ hideConfirm ? "visibility_off" : "visibility" }}</mat-icon>
                        </button>
                        <mat-error *ngIf="emailUserForm.controls.confirmNewPassword.invalid && emailUserForm.controls.confirmNewPassword.touched"> Password do not match </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <button type="submit" mat-button class="resetBtn mt-5" (click)="submitForm()">Reset Password</button>
                <!-- <h4 class="terms"> <a class="policies">Forget password?</a></h4> -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid sm-device">
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-12 mt-5">
            <form [formGroup]="emailUserForm">
              <div class="form-layout">
                <h1 class="text-white"><b>RESET PASSWORD</b></h1>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field appearance="standard">
                      <mat-label>New Password</mat-label>
                      <input matInput formControlName="newPassword" [type]="hide ? 'password' : 'text'" required />
                      <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                      </button>
                      <mat-hint>Password policy : Password must be atleast 8 character in length.Atleast 1 uppercase (A-Z).Atleast 1 lowercase (a-z).Atleast 1 number (0-9).Atleast 1 special characters (eg : !@#$%^&*)."</mat-hint>
                      <mat-error *ngIf="emailUserForm.controls.newPassword.invalid"> Please enter a valid email address </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 mt-5">
                    <mat-form-field appearance="standard">
                      <mat-label>Confirm New Password</mat-label>
                      <input matInput formControlName="confirmNewPassword" [type]="hideConfirm ? 'password' : 'text'" required />
                      <button type="button" mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{ hideConfirm ? "visibility_off" : "visibility" }}</mat-icon>
                      </button>
                      <mat-error *ngIf="emailUserForm.controls.confirmNewPassword.invalid"> Password do not match </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <!-- <mat-card class="resetBtn mt-5">
                                        <h3 type="submit" (click)="submitForm()">Reset Password</h3>
                                    </mat-card> -->
              <button type="submit" mat-button class="resetBtn mt-5" (click)="submitForm()">Reset Password</button>
              <!-- <h4 class="terms"> <a class="policies">Forget password?</a></h4> -->
            </form>
          </div>
        </div>
      </div>
      <!-- <div class="imgPlane">
                <img src="../../assets/images/plane_flying.svg" class="planeImage" />
            </div> -->
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
