import { Component, OnInit, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  @Input() rating = 0;
  @Input() size = 14;
  @Input() space = '0.5rem';
  @Input()
  set showLabel(value: boolean) {
    this.isLabel = coerceBooleanProperty(value);
  }

  isLabel = true;

  constructor() { }

  ngOnInit(): void {
  }

}
