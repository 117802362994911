import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private router: Router, private authService: AuthService, private userService: UserService) {
    if (authService.isLoggedIn()) {
      this.userService.userDetails = authService.getUserPayload()
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  

  ngOnInit() {
    document.addEventListener('contextmenu', event => event.preventDefault());
    
  }
  title = 'si-smartgcpro-web-ux';
}
