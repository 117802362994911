import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseDetailsComponent } from 'src/app/home/course-details/course-details.component';

@Component({
  selector: 'app-sample-certificate',
  templateUrl: './sample-certificate.component.html',
  styleUrls: ['./sample-certificate.component.scss']
})
export class SampleCertificateComponent implements OnInit {

  public courseDetail;
  public today = Date.now();
  public showCertificate = []

  constructor(public dialogRef: MatDialogRef<CourseDetailsComponent>,@Inject(MAT_DIALOG_DATA) public data: any ) { 
    // console.log(data)
    

    this.courseDetail = data.data.courseDetails;
    this.courseDetail[0].CourseModules.forEach(res=>{
      if(res.showCertificate){
        this.showCertificate.push(res)
      }
    })
  }

  ngOnInit(): void {
  }

}
