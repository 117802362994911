<section class="header-section bg-background-dark p-4">
  <div class="container-fluid container-lg">
    <h1 class="mat-title text-light my-2">Shopping Cart</h1>
  </div>
</section>

<section class="loader-section py-5 d-flex justify-content-center align-items-center" *ngIf="!isCartLoaded">
  <mat-spinner color="accent"></mat-spinner>
</section>

<section class="deatil-section" *ngIf="isCartLoaded">
  <div class="container-fluid container-lg">
    <h3 class="mat-title text-light-disabled pt-4 mb-2">{{cartLength}} Course(s) in Cart</h3>
  </div>
</section>

<section class="cart-section" *ngIf="isCartLoaded">
  <div class="container-fluid container-lg py-3 border-top-small border-bottom-small border-light-divider">
    <div class="text-center my-5" *ngIf="cartLength === 0">
      <h3 class="text-light-disabled" >No items in Cart</h3>
      <button mat-stroked-button color="accent" routerLink="" >Keep Shopping</button>
    </div>
    
    <div class="row" *ngIf="cartLength !== 0">
      <div class="col-md-9">
        <div class="course-cart-container d-flex flex-column flex-sm-row mb-3"
          *ngFor="let cartItem of databaseUser; let i = index;">
          <img class="course-cart-img" src="{{cartItem.videoUrl}}" alt="Suresh Dasari Card">
          <div class="course-cart-content flex-fill d-flex flex-column">
            <div class="course-cart-details flex-fill d-flex flex-column flex-sm-row">
              <div class="flex-fill mr-sm-2">
                <h4 class="course-cart-title text-light" (click)="clickCourse(cartItem)">{{cartItem.courseName}}</h4>
                <p class="course-cart-desc text-light-disabled">{{cartItem.courseDesc}}</p>
              </div>
              <div class="ml-sm-auto d-flex flex-row flex-sm-column">
                <p class="course-cart-price">{{cartItem.price | currency:'INR':'symbol-narrow'}}</p>
                <p class="course-cart-actual-price text-light-divider ml-2 ml-sm-0" *ngIf="cartItem.price != cartItem.strikedPrice">{{cartItem.strikedPrice | currency:'INR':'symbol-narrow'}}</p>
              </div>

            </div>
            <div class="course-cart-actions">
              <a class="text-section-title" (click)="moveToWishlist(i,cartItem)">Move to Wishlist</a>
              <a class="text-accent" (click)="removeCart(i,cartItem)">Remove</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="cart-checkout-container px-3">
          <h2 class="checkout-title mat-subheading text-light-secondary mb-0">Total :</h2>
          <p class="cart-checkout-price mat-display-1 text-light font-weight-normal mb-0">{{totalPrice | currency:'INR':'symbol-narrow'}}</p>
          <p class="cart-checkout-actual-price mat-subheading text-accent font-weight-light" *ngIf="totalPrice != strikedPrice"><del>{{strikedPrice |currency:'INR':'symbol-narrow'}}</del></p>
          <p class="cart-checkout-discount mat-subheading text-accent font-weight-light" *ngIf="totalPrice != strikedPrice">{{discountPercent}}% off</p>
          <button mat-flat-button color="accent" class="w-100" (click)="checkout()">Checkout</button>
        </div>
      </div>
    </div>
  </div>
</section>