import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  public subject = new BehaviorSubject<any>(true);

  baseUrl= environment.serverUrl;
  url = environment.serverUrl  + '/courses';
  testimonialUrl = environment.serverUrl + '/testimonials';
   data1:any;

  public sharedData;
  public filterLength;
  public searchKeyword='';
  public coursePurchased;

  constructor(private http: HttpClient) { }

  sendMessage(product:boolean) {
    this.subject.next(product);
    }
     
    // clearMessage() {
    // this.subject.next();
    // }
     
    getMessage(): Observable<any>{
    return this.subject.asObservable();
    }

  getCourses(id) {
    const userId = {
      'userId': id,
    }
    return this.http.get<any>(this.url, { params: userId })
    .pipe(
      
      map((result)=>{
        // console.log(result)
        return{
          coursesData : result.data.map((data,index)=>{
            // console.log(data)
            return{
              courseId : data.courseId,
              videoUrl : data.backgroundImage,
              thumbnailImage: data.thumbnailImage,
              backgroundImage: data.backgroundImage,
              courseIntroVideo: data.courseIntroVideo,
              courseName : data.courseName,
              price : data.price,
              longDesc : data.description,
              strikedPrice : data.strikedPrice,
              description : data.courseDesc.split('.'),
              duration : data.duration,
              totalRating : data.totalRating,
              averageRating : data.averageRating,
              userCourseId: data.userCourseId,
              categoryDesc  : data.categoryDesc,
              categoryName : data.categoryName,
              courseType: data.courseType,
              // newDesc : (data.description as String).substr(0,100) + '...',
              newDesc : data.newCourseDesc,
              instructor : data.Instructors.map((instdetail,index)=>{
                return{
                  firstName : instdetail.firstName,
                  lastName : instdetail.lastName,
                  designation : instdetail.designation,
                  instructorDescription : instdetail.description,
                  courseInsructor : instdetail.CourseInstructor.courseId,

                }
                
              }),
              // courseLearning : data.courseLearnings.map((leaningDetail,index)=>{
              //   return{
              //     learningText : leaningDetail.learningText,
              //   }
              // }),
              // coursePreReqs : data.coursePreReqs.map(PreReq=>{
              //   return{
              //     preReqText : PreReq.preReqText
              //   }
              // }),
              // acrreditations : data.acrreditations.map(acrreditations=>{
              //   return{
              //     accreditationsName : acrreditations.accreditationsName,
              //     accreditationsLogo : acrreditations.accreditationsLogo,
              //   }
              // }),
              // CourseModules : data.CourseModules.map(CourseModules=>{
              //   return{
              //     courseModuleId : CourseModules.courseModuleId,
              //     courseId : CourseModules.courseId,
              //     moduleName : CourseModules.moduleName,
              //     CourseLessons : CourseModules.CourseLessons.map(CourseSubModules=>{
              //       return{
              //         CourseLessonId : CourseSubModules.CourseLessonId,
              //         courseModuleId : CourseSubModules.courseModuleId,
              //         lessonName : CourseSubModules.lessonName,
              //         lessonUrl : CourseSubModules.lessonUrl,
              //         duration : CourseSubModules.duration,
              //       }
              //     })
              //   }
              // }),
              // userRatings : data.userRatings.map((userRating)=>{
              //   return{
              //     rating : userRating.rating,
              //     reviews : userRating.reviewComments,
              //     firstName : userRating.User.firstName,
              //     lastName : userRating.User.lastName,
              //     profileImage : userRating.User.profileImage,

              //   }
              // }),

              // courseCertifications : data.courseCertifications.map(courseCertifications=>{
              //   return{
              //     courseId : courseCertifications.courseId,
              //     certificationHead : courseCertifications.certificationHead,
              //   }
              // }),

              // courseAreas : data.courseAreas.map(courseAreas=>{
              //   return{
              //     courseAreaName : courseAreas.courseAreaName,
              //   }
              // })

            }

          }),

          // instructorData : result.Instructors.map((data,index)=>{
          //   return{
          //     instructorfirstName : data.firstName,
          //     instructorlastName : data.lastName,
          //     instructorDesignation : data.designation,
          //     description : data.description,
          //     courseInstructor : data.CourseInstructor,

          //   }
          // })
        }

      })
    )
  }

  getTestmonials() {
    return this.http.get<any>(this.testimonialUrl)
    .pipe (
      map((result)=>{
        return{
          testimonials : result.data.map(data=>{
            return{
              userId : data.userRating.userId,
              courseId : data.userRating.courseId,
              rating : data.userRating.rating,
              reviewComments : data.userRating.reviewComments,
              userfirstName : data.userRating.User.firstName,
              userlastName : data.userRating.User.lastName,
              profileImage : data.userRating.User.profileImage,
              course : data.userRating.Course.courseName,


            }
          })
        }
      })
    )
  }

  getCourseDetail(courseId) {
    return this.http.get<any>(this.url + `/${courseId}`)
    .pipe(map(result => {
      return{
        coursesData : result.data.map((data,index)=>{
          return{
            courseId : data.courseId,
            videoUrl : data.thumbnailImage,
            backgroundImage: data.backgroundImage,
            courseIntroVideo: data.courseIntroVideo,
            courseName : data.courseName,
            price : data.price,
            longDesc : data.description,
            strikedPrice : data.strikedPrice,
            discountPercent : (((data.strikedPrice - data.price ) / data.strikedPrice) * 100).toFixed(2).slice(0,2),
            description : data.courseDesc.split('<br/>'),
            duration : data.duration,
            totalDuration: data.totalDuration,
            totalRating : data.totalRating,
            courseType : data.courseType,
            averageRating : data.averageRating,
            categoryDesc  : data.categoryDesc,
            categoryName : data.categoryName,
            instructor : data.Instructors.map((instdetail,index)=>{
              return{
                firstName : instdetail.firstName,
                lastName : instdetail.lastName,
                designation : instdetail.designation,
                instructorDescription : instdetail.description,
                courseInsructor : instdetail.CourseInstructor.courseId,

              }
              
            }),

            vrCourses : data.vrCourses.map((vr=>{
              return{
                vrCourseId : vr.vrCourseId,
                courseId : vr.courseId,
                url : vr.url,
                vendorName : vr.vendorName,
                city : vr.City.city,
                state : vr.City.state,
                district : vr.City.district
              }
            })),
            courseLearning : data.courseLearnings.map((leaningDetail,index)=>{
              return{
                learningText : leaningDetail.learningText,
              }
            }),
            coursePreReqs : data.coursePreReqs.map(PreReq=>{
              return{
                preReqText : PreReq.preReqText
              }
            }),
            acrreditations : data.acrreditations.map(acrreditations=>{
              return{
                accreditationsName : acrreditations.accreditationsName,
                accreditationsLogo : acrreditations.accreditationsLogo,
              }
            }),
            CourseModules : data.CourseModules.map(CourseModules=>{
              return{
                courseModuleId : CourseModules.courseModuleId,
                courseId : CourseModules.courseId,
                orders: CourseModules.orders,
                moduleName : CourseModules.moduleName,
                showCertificate: CourseModules.showCertificate,
                CourseLessons : CourseModules.CourseLessons.map(CourseSubModules=>{
                  return{
                    CourseLessonId : CourseSubModules.CourseLessonId,
                    courseModuleId : CourseSubModules.courseModuleId,
                    lessonName : CourseSubModules.lessonName,
                    lessonUrl : CourseSubModules.standardVideo,
                    duration : CourseSubModules.duration,
                  }
                })
              }
            }),
            userRatings : data.userRatings.map((userRating)=>{
              return{
                rating : userRating.rating,
                reviews : userRating.reviewComments,
                firstName : userRating.User.firstName,
                lastName : userRating.User.lastName,
                profileImage : userRating.User.profileImage,

              }
            }),

            courseCertifications : data.courseCertifications.map(courseCertifications=>{
              return{
                courseId : courseCertifications.courseId,
                certificationHead : courseCertifications.certificationHead,
              }
            }),

            courseAreas : data.courseAreas.map(courseAreas=>{
              return{
                courseAreaName : courseAreas.courseAreaName,
              }
            })

          }

        }),

        // instructorData : result.Instructors.map((data,index)=>{
        //   return{
        //     instructorfirstName : data.firstName,
        //     instructorlastName : data.lastName,
        //     instructorDesignation : data.designation,
        //     description : data.description,
        //     courseInstructor : data.CourseInstructor,

        //   }
        // })
      }
    }))
  }

  revisitVideo(lessonId){
    const obj = {
      'lessonId': lessonId,
    }

    return this.http.get<any>(this.url + '/lesson', { params: obj }).pipe(map(res=>{
      return res;
    }))
  }

  // getadvertisementData(){
  //   return this.http.get<any>(this.baseUrl + '/advertisement/ourclient')
  //   .pipe(map(res=>{
  //     // console.log(res)
  //     return res;
  //   }))
  // }
}
