import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUPComponent implements OnInit {

  constructor(public authservice: AuthService, public snackBar: MatSnackBar, private service: UserService, private router: Router) { 
    this.authservice.deleteToken()
  }

  ngOnInit(): void {
  }

  async signupWithGoogle() {
    let signUpUser = await this.authservice.signupWithGoogle()
    let idToken = await this.authservice.getIdToken()
    console.log(idToken,'iamid token1')
    if (signUpUser.additionalUserInfo.isNewUser) {
      // const userDetails = {
      //   userfirstName: signUpUser.additionalUserInfo.profile["given_name"],
      //   userlastName: signUpUser.additionalUserInfo.profile["family_name"],
      //   userEmail: signUpUser.additionalUserInfo.profile["email"],
      //   usermode: "Google",
      //   // logintype: 'Web',
      // }
      const userDetails={
        idToken:idToken,
        logintype: 'Web',
      }
      this.service.createUsers(userDetails).subscribe(result => {
        if (result.success) {
          this.service.userDetails = result;
          this.service.setItem(result.token)
          this.router.navigate([''])
        } else {
          Swal.fire({
            icon: 'error',
            text: "User already exists !",
            showConfirmButton: true,
            heightAuto: false,
          })
        }

      })
      // this.router.navigate(['layout/dashboard'])
    } else {
      // this.snackBar.open('User already exists !', 'Login',{
      //   duration : 3000
      // }); 
      Swal.fire({
        icon: 'error',
        text: "User already exists !",
        showConfirmButton: true,
        heightAuto: false,
      })

    }

  }

  async signupWithFacebook() {
    let fbUser = await this.authservice.signupWithFacebook()
    if (fbUser.additionalUserInfo.isNewUser) {
      const userDetails = {
        userfirstName: fbUser.additionalUserInfo.profile["first_name"],
        userlastName: fbUser.additionalUserInfo.profile["last_name"],
        userEmail: fbUser.additionalUserInfo.profile["email"],
        usermode: "Facebook",
        logintype: 'Web',
      }
      this.service.createUsers(userDetails).subscribe(result => {
        if (result.success) {
          this.service.userDetails = result;
          this.service.setItem(result.token)
          this.router.navigate([''])
        } else {
          this.snackBar.open('Email already exist', 'Please signup with another email', {
            duration: 2000
          });
        }

      })
    } else {
      this.snackBar.open('User already exists !', 'Login', {
        duration: 3000
      });
    }






  }

}
