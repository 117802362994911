import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { passwordValidator, MyErrorStateMatcher } from './validator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-password-reset',
  templateUrl: './confirm-password-reset.component.html',
  styleUrls: ['./confirm-password-reset.component.scss']
})
export class ConfirmPasswordResetComponent implements OnInit {

  public emailUserForm;
  public oobcode;
  public mode;
  public hideConfirm = true;
  public hide = true;
  matcher = new MyErrorStateMatcher();

  constructor(public authservice: AuthService,  private formBuilder: FormBuilder, public router: Router, private activatedActivated: ActivatedRoute) { 
    this.emailUserForm = this.formBuilder.group({
      newPassword: ['', [Validators.required,  Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],    
      confirmNewPassword: ['', passwordValidator],    
    });
    
    this.mode = this.activatedActivated.snapshot.queryParams['mode']
    this.oobcode = this.activatedActivated.snapshot.queryParams['oobCode']

    this.authservice.handleResetPassword(this.oobcode)

   
  }

  submitForm(){
    const password = {
      newPass :  this.emailUserForm.get('newPassword').value,
      confirmPass :  this.emailUserForm.get('confirmNewPassword').value,
    }
    if(this.emailUserForm.valid){
      if(password.newPass === password.confirmPass){
        this.authservice.confirmPasswordReset( this.oobcode , password.newPass).then(()=>{
          Swal.fire({

            icon: 'success',
            text: 'Password Changed !',
            footer: '<div>Now you can login with your new password</div>',
            showConfirmButton: true,
            heightAuto: false,
          }).then(result=>{
            if(result.isConfirmed){
              this.router.navigate(['/email-login'])
            }
          })
          
        })
      }
    }else {
      Swal.fire({

        icon: 'error',
        text: 'Invalid Password !',
        footer: '<div>Please Check Your Password</div>',
        showConfirmButton: true,
        // timer: 5000,
        heightAuto: false,
      })
    }

  }

  ngOnInit(): void {

   
  }



}
