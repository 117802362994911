import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizComponent } from 'src/app/course/quiz/quiz.component';

@Component({
  selector: 'app-mark-modal',
  templateUrl: './mark-modal.component.html',
  styleUrls: ['./mark-modal.component.css']
})
export class MarkModalComponent implements OnInit {
  public total;

  constructor(public dialogRef: MatDialogRef<QuizComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.total=data.data.marks
  }

  ngOnInit(): void {
    console.log(this.data,"mark-modal data")
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
