import { Component, OnInit, NgZone } from '@angular/core';
import { OnlinePaymentService } from 'src/app/services/online-payment.service';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyCartService } from 'src/app/services/my-cart.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { AuthService } from 'src/app/services/auth.service';
import { CityService } from 'src/app/services/city.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
declare var Razorpay: any;
@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.scss'],
})
export class OnlinePaymentComponent implements OnInit {
  hide = false;
  public orderDetails;
  public transactionDetails = [];
  public fullName;
  public billingAddress: FormGroup;
  public sharedDetails;
  public cartDetails = [];
  public email;
  public redirect_url;
  public instructorName;
  public categoryDetails = [];
  public userName;
  public firstLetter;
  public cartBadge;
  public userDetails;
  public databaseUser;
  public wishlistUser;
  public state = [];
  public getCity: any = [];
  public filteredState = [];
  public isShowDiv = false;
  public disabled: boolean = false;
  public cart: boolean = false;
  public values;
  filteredOptions: Observable<string[]>;
  filterStateOptions: Observable<string[]>;
  constructor(
    public courseService: CommonServiceService,
    public onlinePaymentService: OnlinePaymentService,
    public authservice: AuthService,
    public userservice: UserService,
    public cartService: MyCartService,
    public searchService: SearchFilterService,
    public wishlist: WishlistService,
    public cityService: CityService,
    public router: Router,
    private formBuilder: FormBuilder,
    private ngZone: NgZone
  ) {
    if (this.userservice.userDetails != undefined) {
      this.userName = this.userservice.userDetails.data;
      this.cart = true;
      this.firstLetter =
        this.userName.firstName.slice(0, 1) +
        this.userName.lastName.slice(0, 1);
    } else {
      this.cart = false;
    }

    this.values = this.courseService.searchKeyword;

    if (this.userName != undefined) {
      this.cartService
        .getCartdetails(this.userName.userId)
        .subscribe((data) => {
          this.cartBadge = data.cartData.length;
        });
    }
    
    this.onlinePaymentService
      .razorpayPayment(this.userservice.userDetails.data.email,this.cartService.sharedData.totalCost)
      .subscribe((data) => {
        this.searchService
          .getCategoryDetails(this.userName.userId)
          .subscribe((category) => {
            category.CategoryData.forEach((data) => {
              this.categoryDetails.push(data);
            });
          });

        this.onlinePaymentService
          .getTransactionDetails(this.userName.userId)
          .subscribe((data) => {
            this.transactionDetails = data.transactionDetails;
          });

        this.cityService.getCity().subscribe((city) => {
          city.city.forEach((x) => {
            this.getCity.push(x);
            if (!this.state.some((a) => a === x.state)) {
              this.state.push(x.state);
            }
          });

          // this.getCity.sort(this.compare)
          // console.log(this.getCity)
          // this.filteredState = this.state
        });

        // if( this.userservice.userDetails != undefined){
        //   this.userName = this.userservice.userDetails.data
        //   this.cart = true;
        //   this.firstLetter = this.userName.firstName.slice(0,1) + this.userName.lastName.slice(0,1)
        // }else{
        //   this.cart = false;
        // }

        // if(this.userName != undefined){
        //   this.cartService.getCartdetails(this.userName.userId).subscribe(data=>{
        //     this.cartBadge = data.cartData.length
        //   })
        // }

        this.orderDetails = data.order;
        this.email = this.userservice.userDetails.data.email;
        this.fullName =
          this.userservice.userDetails.data.firstName +
          ' ' +
          this.userservice.userDetails.data.lastName;
        this.instructorName =
          this.sharedDetails.instructor[0].firstName +
          ' ' +
          this.sharedDetails.instructor[0].lastName;
      });
    this.billingAddress = this.formBuilder.group({
      Address1: ['', Validators.required],
      Address2: ['', Validators.required],
      City: ['', [Validators.required]],
      Pincode: ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
      Country: ['India', Validators.required],
      State: ['', Validators.required],
    });

    // this.sharedDetails = this.cartService.sharedData

    // this.cartDetails = this.cartService.courseDetails
  }

  onKey(event: any) {
    // without type info
    this.values = event;
    this.courseService.searchKeyword = this.values;
  }

  compare(a, b) {
    const bandA = a.city.toUpperCase();
    const bandB = b.city.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  BacktoCart() {
    this.router.navigate(['layout/my-cart']);
  }

  clickTransaction() {
    if (this.transactionDetails.length === 0) {
      Swal.fire({
        icon: 'info',
        text: 'No transactions found !',
        showConfirmButton: true,
        heightAuto: false,
        allowOutsideClick: false,
      });
    } else {
      this.router.navigate(['/layout/transactions']);
    }
  }

  ngOnInit(): void {
    if (this.cartService.sharedData === undefined) {
      this.router.navigate(['layout/my-cart']);
    } else {
      this.sharedDetails = this.cartService.sharedData;

      this.cartDetails.push(this.cartService.courseDetails);
    }

    this.filteredOptions = this.billingAddress.get('City').valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val))
    );

    this.filterStateOptions = this.billingAddress
      .get('State')
      .valueChanges.pipe(
        startWith(''),
        map((val) => this.filterState(val))
      );
    // console.log(this.filteredOptions);
    // console.log(this.filterStateOptions);
  }

  filter(val: string): string[] {
    return this.getCity
      .map((x) => x.city)
      .filter((option) => option.toLowerCase().includes(val.toLowerCase()));
  }

  filterState(val: string): string[] {
    return this.state
      .map((x) => x)
      .filter((option) => option.toLowerCase().includes(val.toLowerCase()));
  }

  async logout() {
    this.userservice.userDetails = undefined;
    let service = await this.authservice.logout();
    this.userservice.logout();
    this.cart = false;
    this.disabled = false;
  }

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  clickInput() {
    this.courseService.searchKeyword = this.values;
    this.router.navigate(['/search']);
  }

  toggleClick() {
    this.hide = !this.hide;
  }

  over() {
    this.isShowDiv = true;
  }

  out() {
    this.isShowDiv = false;
  }

  overDisplay() {
    this.isShowDiv = false;
  }

  exploreAllCourses() {
    this.courseService.searchKeyword = '';
    this.router.navigate(['/search']);
  }

  clickCart() {
    this.userDetails = this.userservice.userDetails.data;
    this.cartService
      .getCartdetails(this.userDetails.userId)
      .subscribe((data) => {
        this.databaseUser = data;
        if (this.databaseUser.cartData.length === 0) {
          Swal.fire({
            icon: 'info',
            text: 'No items in Cart !',
            showConfirmButton: true,
            heightAuto: false,
            allowOutsideClick: false,
          });
        } else {
          this.router.navigate(['layout/my-cart']);
        }
      });
  }

  clickWishList() {
    this.userDetails = this.userservice.userDetails.data;
    this.wishlist
      .getWishlistCourse(this.userDetails.userId)
      .subscribe((data) => {
        this.wishlistUser = data;
        if (this.wishlistUser.wishlistData.length === 0) {
          Swal.fire({
            icon: 'info',
            text: 'No items in Wishlist !',
            showConfirmButton: true,
            heightAuto: false,
            allowOutsideClick: false,
          });
        } else {
          this.router.navigate(['layout/wishlist']);
        }
      });
  }

  clickCourse(selectedCourse) {
    this.courseService.sharedData = selectedCourse;
    this.router.navigate(['/course-details'], {
      queryParams: { courseId: selectedCourse.courseId },
    });
  }

  // filterState(value) {
  //   console.log(value)
  //   this.filteredState = []
  //   let state = this.getCity.find(el => el.city === value)
  //   this.filteredState.push(state.state)
  // }

  paymentCompleted(response) {
    // console.log(response)
    if (response.razorpay_payment_id) {
      let formDetails = {
        addressLine1: this.billingAddress.get('Address1').value,
        addressLine2: this.billingAddress.get('Address2').value,
        city: this.billingAddress.get('City').value,
        pincode: this.billingAddress.get('Pincode').value,
        country: this.billingAddress.get('Country').value,
        state: this.billingAddress.get('State').value,
      };
      let address =
        this.billingAddress.get('Address1').value +
        ',' +
        ' ' +
        this.billingAddress.get('Address2').value +
        ',' +
        ' ' +
        this.billingAddress.get('City').value +
        ',' +
        ' ' +
        this.billingAddress.get('Pincode').value +
        ',' +
        ' ' +
        this.billingAddress.get('Country').value +
        ',' +
        ' ' +
        this.billingAddress.get('State').value;
      const details = {
        emailId: this.email,
        userId: this.userservice.userDetails.data.userId,
        payment: 'Credit Card',
        totalCost: this.sharedDetails.totalCost,
        courses: this.cartDetails[0],
        orderId: response.razorpay_order_id,
        paymentId: response.razorpay_payment_id,
        billingAddress: address,
        city: this.billingAddress.get('City').value,
        pincode: this.billingAddress.get('Pincode').value,
        country: this.billingAddress.get('Country').value,
        state: this.billingAddress.get('State').value,
      };
      this.onlinePaymentService.billingAddress = address;
      this.onlinePaymentService
        .createUserTransaction(details)
        .subscribe((data) => {
          if (data.success) {
            this.onlinePaymentService.billingAddress = data.data.billingAddress;

            this.ngZone.run(() =>
              this.router.navigate(['layout/transaction-success'])
            );
            // this.ngZone.run
          }
        });
    }
  }

  clickPayment() {
    if (this.billingAddress.valid) {
      let formDetails = {
        addressLine1: this.billingAddress.get('Address1').value,
        addressLine2: this.billingAddress.get('Address2').value,
        city: this.billingAddress.get('City').value,
        pincode: this.billingAddress.get('Pincode').value,
        country: this.billingAddress.get('Country').value,
        state: this.billingAddress.get('State').value,
      };

      // console.log(formDetails);

      let options = {
        key: 'rzp_test_4CbhCC2dClxWDT', // Enter the Key ID generated from the Dashboard
        amount: this.sharedDetails.totalCost, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: this.orderDetails.currency,
        name: this.fullName,
        order_id: this.orderDetails.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          this.paymentCompleted(response);
        }.bind(this),

        prefill: {
          name: this.fullName,
          email: this.userservice.userDetails.data.email,
          contact: '',
          amount: this.orderDetails.amount,
        },
        notes: {
          billing_name: this.fullName,
          billing_address_country: formDetails.country,
          billing_address_zip: formDetails.pincode,
          billing_address_state: formDetails.state,
          billing_address_line1: formDetails.addressLine1,
          billing_address_line2: formDetails.addressLine2,
          billing_address_city: formDetails.city,
        },
        theme: {
          color: '#42414D',
        },
      };

      // options.handler = this.paymentCompleted
      let rzp1 = new Razorpay(options);
      rzp1.open();
    } else {
      const invalid = [];
      const controls = this.billingAddress.controls;
      for (const value in controls) {
        if (controls[value].invalid) {
          invalid.push(value);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Oops, Something went wrong',
        text:
          invalid.join(', ') +
          ' ' +
          'field has been left blank. Enter the value in the competency field before proceeding. ',
        showConfirmButton: true,
        heightAuto: false,
        allowOutsideClick: false,
      });
    }
  }
}
