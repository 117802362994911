import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authService: AuthService, private router: Router, private service: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the JWT token from the AuthService
    const token = this.authService.getToken();

    // Clone the request and add the JWT token to the Authorization header
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });

    // Send the modified request using the HttpHandler
    return next.handle(authReq).pipe(
      catchError((error: any) => {
        if (error.status == 500) {
          // console.log(error, 'data error');
          if (error.status === 500) {
            Swal.fire({
              icon: 'error',
              text: error.error.message,
              showConfirmButton: true,
              heightAuto: false,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
          }
        }

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            console.log('401 error');
            return this.handle401Error(req, next);
          } else if (error.status === 403) {
            console.log('iam in 403');

            // Token expired, log out the user and redirect to login page
            this.authService.logoutafter();
            this.router.navigate(['/email-login']);
          }
        }
        return throwError(error);
      }),
      finalize(() => {})
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.getRefreshToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.refreshTokenSubject.next(newToken);
            // Clone the original request and add the new token
            const authReq = req.clone({
              headers: req.headers.set('Authorization', `Bearer ${newToken}`),
            });
            // return next.handle(authReq);

            // Return the modified request
            return next.handle(authReq).pipe(
              tap((event: HttpEvent<any>) => {
                // If the request was successful, you can handle the response here
                if (event instanceof HttpResponse) {
                  const updatedTokenNew = event.headers.get('Authorization'); // Replace with your actual header name
                  if (updatedTokenNew) {
                    const updatedToken = updatedTokenNew.replace(/^Bearer\s/, '');
                    this.service.setItem(updatedToken);
                  }
                }
              })
            );
          }

          // If refresh token fails, log out the user and redirect to login page
          this.authService.logout();
          this.router.navigate(['/email-login']);

          return throwError('Token refresh failed');
        }),
        catchError(() => {
          // If refresh token fails, log out the user and redirect to login page
          this.authService.logout();
          this.router.navigate(['/email-login']);
          return throwError('Token refresh failed');
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          // Clone the original request and add the new token
          const authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          });
          return next.handle(authReq);
        })
      );
    }
  }
}
