
import { AbstractControl, FormGroup, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export function passwordValidator(control : AbstractControl){
    if(control && (control.value !== null || control.value !== undefined)){
        const confirmPasswordValue = control.value;
        const passwordControl = control.root.get('newPassword')
        if(passwordControl){
            const passwordValue = passwordControl.value;
            if(passwordValue !== confirmPasswordValue){
                return{
                    isError: true
                }
            }
        }
    }
    return null;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
    return (invalidCtrl || invalidParent);  }}