import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { UserService } from 'src/app/services/user.service';
import { MyCartService } from 'src/app/services/my-cart.service';
import { SearchService } from 'src/app/services/search.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchFilterService } from 'src/app/services/search-filter.service';

@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss'],
})
export class LayoutContainerComponent implements OnInit, OnDestroy {
  cartCountSubscription: Subscription;
  userDetailsSubscription: Subscription;

  isLoggedin = false;
  values: string;
  cartBadge = 0;
  userName: any;
  firstLetter: any;
  userDetails: any;

  public categoryDetails: any = [];

  constructor(
    public authservice: AuthService,
    public service: CommonServiceService,
    public userservice: UserService,
    public searchService: SearchFilterService,
    public cartService: MyCartService,
    public searchKey: SearchService,
    public router: Router
  ) {
    this.cartCountSubscription = this.cartService.getCartCountListener().subscribe((cartCount) => {
      this.cartBadge = cartCount;
    });

    this.userDetailsSubscription = this.userservice.getUserDetailsListener().subscribe((userDetails) => {
      if (this.userservice.userDetails !== undefined) {
        this.isLoggedin = true;
        this.userName = this.userservice.userDetails.data;
        this.firstLetter = this.userName.firstName.slice(0, 1) + this.userName.lastName.slice(0, 1);
      } else {
        this.isLoggedin = false;
      }
    });
    this.userservice.getStoredUserDetails();
    this.cartService.getCartCount();

    this.searchService.getCategoryDetails('').subscribe((category) => {
      category.CategoryData.forEach((data) => {
        this.categoryDetails.push(data);
      });
    });
  }
  ngOnDestroy(): void {
    this.cartCountSubscription.unsubscribe();
    this.userDetailsSubscription.unsubscribe();
  }

  ngOnInit(): void {}

  clickCourse(selectedCourse) {
    if (selectedCourse.userCourseId && selectedCourse.courseType !== 'VR') {
      console.log('two');

      this.router.navigate(
        [
          '/course-details',
          {
            courseId: selectedCourse.courseId,
            userCourseId: selectedCourse.userCourseId,
          },
        ],
        (this.service.sharedData = selectedCourse)
      );
    } else {
      console.log('0ne');
      this.service.sharedData = selectedCourse;
      this.router.navigate(['/course-details'], { queryParams: { courseId: selectedCourse.courseId } });
    }
  }

  viewAllCourses() {
    this.service.searchKeyword = '';
    this.router.navigate(['/search'], { queryParams: { query: '' } });
  }

  async logout() {
    const service = await this.authservice.logout();
    this.userservice.logout();
  }

  onKeyup(event) {
    // without type info
    this.values = event;
    this.service.searchKeyword = this.values;
  }

  clickInput() {
    this.service.searchKeyword = this.values;
    this.router.navigate(['/search'], { queryParams: { query: this.values } });
  }
}
