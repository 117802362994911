<mat-sidenav-container>
  <mat-sidenav-content>
    <div class="flex-grow-1 flex-md-grow-0 text-center text-md-left p-4">
      <a class="text-center" href="#">
        <!-- <img class="nav-logo" src="../../../assets/images/smartgdpro_logo.svg"> -->
        <img src="../../assets/smartgclogoblue.png" alt="SMARTGCPRO" class="nav-logo" />
      </a>
    </div>
    <section class="hero-section">
      <div class="container-fluid container-lg lg-device">
        <div class="mainDiv">
          <div class="row">
            <div class="col-lg-12 col-md-12 mt-5">
              <div class="bg-white text-dark textContent">
                <h1><b>Your e-mail has been verified !</b></h1>
                <h4>You can now SIGN IN with your new account</h4>
                <button mat-button routerLink="/email-login" routerLinkActive="active">SIGN IN</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid sm-device">
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-12 mt-5">
            <div class="bg-white text-dark textContent">
              <h1><b>Your e-mail has been verified !</b></h1>
              <h4>You can now SIGN IN with your new account</h4>
              <button mat-button routerLink="/email-login" routerLinkActive="active">SIGN IN</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="imgPlane">
                <img src="../../assets/images/plane_flying.svg" class="planeImage" />
            </div> -->
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
