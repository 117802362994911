<!-- <app-header></app-header> -->

<!-- <section class="topHeader"> -->
<!-- <app-side-bar></app-side-bar> -->
<!-- <app-landing-page-top></app-landing-page-top> -->
<router-outlet></router-outlet>


<!-- <app-student-dashboard></app-student-dashboard> -->

<!-- </section> -->
<!-- <app-footer></app-footer> -->
<!-- <app-sign-up></app-sign-up> -->
<!-- <app-login></app-login> -->

