import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUPComponent } from './sign-up/sign-up.component';
import { AuthGuard } from './shared/guard/auth-guard';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { MyprofileComponent } from './layout/myprofile/myprofile.component';
import { EmailSignupComponent } from './email-signup/email-signup.component';
import { EmailLoginComponent } from './email-login/email-login.component';
// import { ViewAllCoursesComponent } from './homePageComponents/view-all-courses/view-all-courses.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ConfirmPasswordResetComponent } from './confirm-password-reset/confirm-password-reset.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { UserManagementComponent } from './user-management/user-management.component';


const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./landing-page-top/landing-page-top.module').then(m => m.LandingPageTopModule),
  //   // canActivate: [AuthGuard]
  // },

  {
    path: 'sign-up',
    component: SignUPComponent,
    // canActivate : [AuthGuard]

  },

  {
    path: 'login',
    component: LoginComponent,
    // canActivate : [AuthGuard]
  },

  // {
  //   path: 'view-all-courses',
  //   component: ViewAllCoursesComponent,
  //   // canActivate : [AuthGuard]
  // },

  {
    path: 'email-signup',
    component: EmailSignupComponent,
    // canActivate : [AuthGuard]
  },


  {
    path: 'email-login',
    component: EmailLoginComponent,
    // canActivate : [AuthGuard]

  },
  {
    path: 'layout',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    // canActivate: [AuthGuard],
  },

  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
  },

  {
    path: 'confirm-password-reset',
    component: ConfirmPasswordResetComponent
  },

  {
    path: 'email-verification',
    component: EmailVerificationComponent
  },
  {
    path: 'user-management',
    component: UserManagementComponent
  },
  { path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule) },
  { path: 'course', loadChildren: () => import('./course/course.module').then(m => m.CourseModule) },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'certverify', loadChildren: () => import('./certificate-verify/certificate-verify.module').then(m => m.CertificateVerifyModule) },




];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true ,scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
