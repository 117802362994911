// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  'serverUrl': 'https://dev-api.smartgcpro.com',
  // serverUrl: 'http://localhost:3000',

  firebase: {
    apiKey: 'AIzaSyBiHqcmhp1p7ZViaW5W3OEjYR2tg1B-xAI',
    authDomain: 'smartgcpro-dev.firebaseapp.com',
    projectId: 'smartgcpro-dev',
    storageBucket: 'smartgcpro-dev.appspot.com',
    messagingSenderId: '564601804035',
    appId: '1:564601804035:web:e2b04b3c2347babe33c536',
  },

  captchaUrl: {
    siteKey: '6LeeReEZAAAAADZGqcEJqrdxjJ7l-uoH7xmh9qvn',
    siteUrl: '6LeeReEZAAAAAMxoGfe-6himLeOsOeZRl7oKdiVo',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
