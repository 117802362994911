import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module'
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { LayoutComponent } from './layout.component';
import { MaterialModule } from '../material.module';
import { DashbordCourseDetailsComponent } from './dashbord-course-details/dashbord-course-details.component';
import { MyCartComponent } from './my-cart/my-cart.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { WishlistComponent } from './wishlist/wishlist.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { OnlinePaymentComponent } from './online-payment/online-payment.component';
import { SharedModule } from '../shared.module';
import { FilterPipe } from './dashboard/search-filter.pipe';
import { TransactionSuccessComponent } from './transaction-success/transaction-success.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';




@NgModule({
  declarations: [
    LayoutComponent,
    DashboardComponent,
    MyprofileComponent,
    DashbordCourseDetailsComponent,
    MyCartComponent,
    WishlistComponent,
    TransactionsComponent,
    InvoiceComponent,
    OnlinePaymentComponent,
    FilterPipe,
    TransactionSuccessComponent,
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule, 
    MaterialModule,
    NgbModule ,
    SharedModule,
    NgxQRCodeModule
  ]
})
export class LayoutModule { }
