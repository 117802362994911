import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { passwordValidator, MyErrorStateMatcher } from './validator';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-signup',
  templateUrl: './email-signup.component.html',
  styleUrls: ['./email-signup.component.scss']
})
export class EmailSignupComponent implements OnInit {
  public emailUserForm: FormGroup;
  hide = true;
  hideConfirm = true;
  public siteKey: string;
  public isChecked;
  public siteURL;
  private matchCode = '{Qsh#Q@/68}'
  matcher = new MyErrorStateMatcher();
  aFormGroup: FormGroup;


  constructor(public authservice: AuthService, private formBuilder: FormBuilder, public router: Router, private service: UserService, public snackBar: MatSnackBar, public _renderer: Renderer2) {
    this.authservice.deleteToken()
    this.emailUserForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
      confirmPassword: ['', passwordValidator],
      recaptchaReactive: [''],
      key: ['']
    });

    this.siteKey = environment.captchaUrl.siteKey;
    this.siteURL = environment.captchaUrl.siteUrl

  }

  ngOnInit(): void {



    // let script = this._renderer.createElement('script');
    // script.defer = true;
    // script.async = true;
    // script.src = "https://www.google.com/recaptcha/api.js";
    // this._renderer.appendChild(document.body, script)

  }

  resolved(captchaResponse: string) {
    this.isChecked = captchaResponse
  }

  onSubmit() {
    const emailUsers = {
      userfirstName: this.emailUserForm.get('firstName').value,
      userlastName: this.emailUserForm.get('lastName').value,
      idToken: '',
      // userEmail: this.emailUserForm.get('email').value,
      // usermode: "Email",
      logintype: 'Web',
    }

    const users = {
      userfirstName: this.emailUserForm.get('firstName').value,
      userlastName: this.emailUserForm.get('lastName').value,
      userEmail: this.emailUserForm.get('email').value,
      userPassword: this.emailUserForm.get('password').value,
      confirmPassword: this.emailUserForm.get('confirmPassword').value,
      key: this.emailUserForm.get('key').value,

    }
    if (this.emailUserForm.valid) {
      if (this.isChecked) {
        if (users.userPassword === users.confirmPassword && users.key == this.matchCode) {
          this.authservice.emailSignUp(users).then(data => {
            this.authservice.getIdToken().then(idData => {
              console.log(idData, 'iam iddta')
              emailUsers.idToken = idData;
              this.service.createUsers(emailUsers).subscribe(result => {
                if (!result.success) {
                  Swal.fire({
                    icon: 'error',
                    text: 'Email already exist,Please signup with another email',
                    showConfirmButton: true,
                    timer: 5000,
                    heightAuto: false,
                  })
                }
              })
            })

          })
            .catch(er => {
              Swal.fire({
                icon: 'error',
                text: er.message,
                showConfirmButton: true,
                timer: 5000,
                heightAuto: false,
              })
            })
        } else {
          Swal.fire({

            icon: 'error',
            text: 'Incorrect Password !',
            footer: '<div>Please Check Your Password</div>',
            showConfirmButton: true,
            heightAuto: false,
          })
        }

      } else {
        Swal.fire({
          title: 'Almost Done !',
          text: "To sign up, please check the box to let us know you're human",
          footer: "Sorry, no robots allowed",
          showConfirmButton: true,
          heightAuto: false,
        })
      }


    } else {
      Swal.fire({
        icon: 'error',
        text: "Invalid form fields",
        showConfirmButton: true,
        heightAuto: false,
      })
    }


  }


}
