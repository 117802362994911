
 <div class="container-fluid">
     <ng-container  *ngIf="!feedback && !modified">
        <div class="d-flex">
            <h1 mat-dialog-title class="mt-2" style="color: #FFFFFFDE;font-family: Montserrat, Regular;">Course Rating</h1>
            <button mat-icon-button (click)="onNoClick()"  class="ml-auto">
                <mat-icon class="text-white">clear</mat-icon>
            </button>
        </div>
        <div class="dialog-input text-center mt-5">
            <p style="color: #FFFFFF99;font-size: 16px;font-family: Montserrat, Regular;">How much do you rate this course</p>
            <div class="row justify-content-center star-rating">
                <div *ngFor="let star of stars">
                    <ul class="rating-list" >
                        <li (click)="countStar(star)" class="stars" [ngClass]="{'selected': (star <= selectedValue)}">
                            <i class="fa fa-star"></i>
                        </li>
                    </ul>
                </div>
              </div>
            <form [formGroup]="feedbackForm" class="mt-5">
                <textarea matInput formControlName="message" type="text" class="form-control" placeholder="Enter your feedback (Optional)"></textarea>
            </form>
            <button mat-button class="mt-5 mb-5 submitBtn" [disabled]="!selectedValue" (click)="onSubmit()">Submit your Rating</button>
        </div>
    </ng-container>
    
    
    <ng-container *ngIf="feedback && !modified">
        <div class="text-right">
            <button mat-icon-button (click)="feedbackDone()"  >
                <mat-icon style="color: #FFFFFFDE;">clear</mat-icon>
            </button>
        </div>
        
        <div class="thank-you mt-5" >
            <mat-icon>emoji_emotions</mat-icon>
            <h2 class="text-white">Thank you for your valuable feedback</h2>
            <h2>Feedback like this helps us constantly improve our customer experiences by knowing what we are doing right and what we can work on. So, we appreciate you taking the time to send us this helpful response.</h2>
        </div>
    </ng-container>
    <ng-container *ngIf="modified">
        <div class="text-right">
            <button mat-icon-button (click)="feedbackModified()"  >
                <mat-icon style="color: #FFFFFFDE;">clear</mat-icon>
            </button>
        </div>
        
        <div class="rating-modification mt-5 p-5" >
            <mat-icon>emoji_emotions</mat-icon>
            <h2 class="text-white mt-3">You're Done!</h2>
            <h2 class="text-white">Thank you for updating the feedback</h2>
        </div>
        
    </ng-container>
 </div>
