import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  public mode;
  public actionCode;

  constructor(public router: Router,public activatedRoute: ActivatedRoute,public authService: AuthService) {}

  ngOnInit(): void {
    this.mode = this.activatedRoute.snapshot.queryParams['mode']
    this.actionCode = this.activatedRoute.snapshot.queryParams['oobCode']

    // switch (this.mode)   {
    //   case 'verifyEmail':
    //     this.authService.handleVerifyEmail( this.actionCode)
    // }
  }

}
