<div class="container-fluid">
    <ng-container>
       <div class="d-flex">
           <h1 mat-dialog-title class="mt-2" style="color: #FFFFFFDE;font-family: Montserrat, Regular;">Assessment-Mark</h1>
       </div>
       <div class="dialog-input text-center mt-5">
        <h1 class="text-light">
            The assessment is marked for a score of &nbsp;{{ total }}. A score
            of 80% is required to pass the assessment.<br />Good Luck!
          </h1>
          <button mat-button class="mt-3  submitBtn"(click)="onNoClick()" >OK</button>
           
       </div>
   </ng-container>
