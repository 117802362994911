
<div class="container-fluid">
    <div class="row open-dialog">
        <div class="col-sm-12-col-md-12-col-lg-12 col-xs-12">
            <div class="text-content mt-5">
                 <p>Are you sure? Once the certificate is downloaded, a reattempt is not possible.</p>
            </div>
           
        </div>
        <div style="margin-top:100px;">
            <button mat-button class="submitBtn" (click)=" onSubmit()">CONFIRM</button>
            <button mat-button class="submitBtn" (click)=" onNoClick()">CANCEL</button>
        </div>
    </div>
</div>

