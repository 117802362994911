import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExamResultComponent } from '../course/exam-result/exam-result.component';

@Injectable({
  providedIn: 'root'
})
export class UserProgressService {

  public recentArray: any = []
  filteredArray: any;
  lessonArray: any[];
  constructor(private http: HttpClient) { }

  url = environment.serverUrl + '/userProgress';

  updateProgress(data) {
    const obj = {
      userCourseId: data.userCourseId,
      courseId: data.courseId,
      userId: data.userId
    }

    return this.http.patch<any>(this.url, obj)
  }

  trackProgress(data) {
    // console.log(data, "data")
    const obj = {
      UserCourseLessonId: data.UserCourseLessonId,
      completedTime: data.completedTime,
      userCourseId: data.userCourseId,
      userCourseModuleId: data.userCourseModuleId,
      courseId: data.courseId,
      duration: data.duration,
      courseType: data.courseType,
      recentPlayedTime: data.recentPlayedTime



    }

    // console.log(obj, "obj")

    return this.http.patch<any>(this.url + '/track', obj);

  }

  getCourse(id, userId) {
    const userCourseId = {
      'userCourseId': id,
      'userId': userId,
    }
    return this.http.get<any>(this.url + '/getCourseDetail', { params: userCourseId })
      .pipe(
        map(result => {
          // console.log(result, 'iam result')
          this.recentArray = []
          this.lessonArray = []
          // result.data[0].UserCourseModules.forEach((ele) => {
          //   ele.UserCourseLessons.forEach(element => {
          //     if (element.lessonStatus == 'COMPLETED' && element.updatedAt != null) {
          //       this.recentArray.push(element)
          //       this.filteredArray = this.recentArray.sort((a, b) => {
          //         return <any>new Date(b.updatedAt).getTime() - <any>new Date(a.updatedAt).getTime()
          //       })

          //     }


          //   });

          // })
          this.recentArray = result.data[0].UserCourseModules.filter((el) => el.userCourseId == userCourseId.userCourseId)

          // console.log(this.recentArray, "recent")
          this.recentArray.forEach(element => {
            // console.log(element,'element')
            element.UserCourseLessons.forEach(ele => {
              // console.log(ele, 'ele')
              if (ele.updatedAt != null) {
                this.lessonArray.push(ele)

              }
            });
          });
          this.filteredArray = this.lessonArray.sort((a, b) => {
            return <any>new Date(b.updatedAt).getTime() - <any>new Date(a.updatedAt).getTime()
          })
          // console.log(this.filteredArray[0] ,'filtered')


          return {
            filterDetails: this.filteredArray.length > 0 ? this.filteredArray[0] : null,
            totalTime: result.totalTime,
            completedTime: this.getMinSec(result.completedTime),
            completedTimeInSec: result.completedTime,
            courseCompletedPercentage: (result.completedTime / result.totalTime) * 100,
            userData: result.data.map(data => {
              return {
                userCourseId: data.userCourseId,
                courseId: data.courseId,
                userId: data.userId,
                courseStatus: data.courseStatus,
                completionDate: data.completionDate,
                courseName: data.Course.courseName,
                courseDesc: data.Course.courseDesc,
                description: data.Course.description,
                rating: data.Course.rating,
                isRating: data.Course.isRating,
                totalReviews: data.Course.totalReviews,
                total: data.Course.thumbnailImage,
                backgroundImage: data.Course.backgroundImage,
                courseIntroVideo: data.Course.courseIntroVideo,
                duration: data.Course.duration,
                price: data.Course.price,
                strikedPrice: data.Course.strikedPrice,
                certification: data.Course.certification,
                categoryId: data.Course.categoryId,
                totalAttempts: data.totalAttempts,
                maxAttempt: data.Course.maxAtttempt,
                // exams: data.Course.exams.map(exam => {
                //   return {
                //     examId: exam.examId,
                //     examText: exam.examText,
                //     solutions: exam.solutions.map(sol => {
                //       return {
                //         solutionId: sol.solutionId,
                //         examId: sol.examId,
                //         solutionText: sol.solutionText,
                //         isCorrect: sol.isCorrect,

                //       }
                //     })
                //   }
                // }),

                userRatings: data.Course.userRatings.map(rating => {
                  return {
                    userRatingId: rating.userRatingId,
                    userId: rating.userId,
                    courseId: rating.courseId,
                    rating: rating.rating,
                    reviewComments: rating.reviewComments,
                  }
                }),

                courseModules: data.UserCourseModules.map(courseModule => {
                  let percentage;
                  let totalCount = 0;
                  let completedCount = 0;
                  courseModule.UserCourseLessons.forEach(x => {
                    totalCount++;
                    if (x.lessonStatus.toLowerCase() === "completed") {
                      completedCount++;
                    }
                  })
                  return {
                    userCourseModuleId: courseModule.userCourseModuleId,
                    userCourseId: courseModule.userCourseId,
                    courseModuleId: courseModule.courseModuleId,
                    moduleStatus: courseModule.moduleStatus,
                    percentage: completedCount > 0 ? (completedCount / totalCount) * 100 : 0,
                    // moduleStatus : courseModule.moduleStatus
                    orders: courseModule.orders,
                    CourseModule: courseModule.CourseModule.courseModuleId,
                    courseId: courseModule.CourseModule.courseId,
                    moduleName: courseModule.CourseModule.moduleName,
                    knowledgeCheck: courseModule.QuizResults[0] ? (courseModule.QuizResults[0].percentage >= 80 ? true : false) : false,
                    questions: courseModule.CourseModule.questions.map(ques => {
                      return {
                        questionId: ques.questionId,
                        courseModuleId: ques.courseModuleId,
                        questionText: ques.questionText,
                        questionType: ques.questionType,
                        fillUp: '',
                        timer: ques.timer,
                        marks: ques.marks,
                        CourseLessonId: ques.CourseLessonId,
                        lessonName: ques.CourseLesson.lessonName,
                        solution: '',
                        disable: false,
                        answers: ques.answers.map(ans => {
                          return {
                            answerId: ans.answerId,
                            answerText: ans.answerText,
                          }
                        }),

                        MatchSolutions: ques.MatchSolutions.map(match => {
                          return {

                            answerText2: match.answerText2,
                            matchsolution: match.solutionIndex ? match.solutionIndex : null,



                          }
                        })
                      }
                    }),

                    CourseLessons: courseModule.UserCourseLessons.map(courseLesson => {
                      // console.log(courseLesson, "courseLesson")
                      return {

                        userCourseLessonId: courseLesson.userCourseLessonId,
                        userCourseModuleId: courseLesson.userCourseModuleId,
                        courseLessonId: courseLesson.courseLessonId,
                        lessonStatus: courseLesson.lessonStatus,
                        completedTime: courseLesson.completedTime,
                        CourseLesson: courseLesson.CourseLesson.CourseLessonId,
                        courseModuleId: courseLesson.CourseLesson.courseModuleId,
                        lessonName: courseLesson.CourseLesson.lessonName,
                        fullHdVideo: courseLesson.CourseLesson.fullHdVideo,
                        hdVideo: courseLesson.CourseLesson.hdVideo,
                        standardVideo: courseLesson.CourseLesson.standardVideo,
                        duration: courseLesson.CourseLesson.duration,
                        durationMinSec: this.getMinSec(courseLesson.CourseLesson.duration),
                        courseType: courseLesson.CourseLesson.courseType,
                        pdfFile: courseLesson.CourseLesson.pdfFile,
                        completedPercentage: (courseLesson.CourseLesson.courseType === 'video') ? (courseLesson.completedTime / courseLesson.CourseLesson.duration) * 100 : (courseLesson.completedTime != 100 ? 0 : 100),
                        recentPlayedTime: courseLesson.recentCompleted,
                      }
                    }),

                    QuizResults: courseModule.QuizResults.map(result => {
                      return {
                        userCourseModuleId: result.userCourseModuleId,
                        percentage: result.percentage,
                        result: result.result,
                      }
                    })



                  }
                }).sort((a, b) => {

                  if (a.orders > b.orders) {

                    return 1

                  } else if (b.orders > a.orders) {

                    return -1

                  }

                  return 0

                }),
                ExamResults: data.ExamResults.map(examResult => {
                  return {
                    examResultId: examResult.examResultId,
                    courseId: examResult.courseId,
                    userId: examResult.userId,
                    userCourseId: examResult.userCourseId,
                    percentage: examResult.percentage,
                    result: examResult.result,
                  }
                }),

                recentProgresses: data.recentProgresses.map(recentProgresses => {
                  return {
                    recentProgressId: recentProgresses.recentProgressId,
                    userCourseId: recentProgresses.userCourseId,
                    userCourseModuleId: recentProgresses.userCourseModuleId,
                    userCourseLessonId: recentProgresses.userCourseLessonId,
                    completedTime: recentProgresses.completedTime,
                  }
                })

              }
            })
          }
        })
      )
  }

  getMinSec(duration) {
    let durationMin;
    let durationSec;

    durationMin = Math.floor(duration / 60);
    durationMin = (durationMin >= 10) ? durationMin : "0" + durationMin;
    durationSec = Math.floor(duration % 60);
    durationSec = (durationSec >= 10) ? durationSec : "0" + durationSec;

    return durationMin + ":" + durationSec
  }

  getExam(courseId, usercourseId) {
    const obj = {
      'courseId': courseId,
      'userCourseId': usercourseId
    }
    return this.http.get<any>(this.url + '/getexam', { params: obj })
      .pipe(
        map(result => {
          return {
            maxAttempt: result.maxAtttempt,
            totalAttempt: result.totalAttempt,

            getExam: result.data.map(exam => {
              return {
                examId: exam.examId,
                examText: exam.examText,
                questionType: exam.questionType,
                inputText: '',
                courseId: exam.courseId,
                timer: exam.timer,
                marks: exam.marks,
                solution: '',
                disable: false,
                solutions:exam.questionType=='MC'|| exam.questionType=='RB'? this.shuffle(exam.solutions):exam.solutions.map(sol => {
                  return {
                    solutionId: sol.solutionId,
                    examId: sol.examId,
                    solutionText: sol.solutionText,
                    solutionText2: '',
                    isCorrect: sol.isCorrect,
                  }
                }),
                MatchSolutions: exam.MatchSolutions.map(match => {
                  return {
                    sloutionId: match.solutionId,
                    solutionText2: match.solutionText2,
                    matchsolution: ''



                  }
                })
              }
            })

          }

        })
      )
  }
  shuffle(array) {
    // console.log(array,'solutions')
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    return array;
  }
  examCount(courseId: any, usercourseId: any) {
    const obj = {
      'courseId': courseId,
      'userCourseId': usercourseId
    }
    return this.http.get<any>(this.url + '/examCount', { params: obj }).pipe(
      map(result => {
        return {
          maxAttempt: result.maxAtttempt,
          totalAttempt: result.totalAttempt,



        }
      })
    )


  }
  // addCount(value){
  //   const obj{

  //   }
  //   return this.http.post<any>(this.url+ '/ addCount', obj)

  // }


}


// function sortData() {
//   throw new Error('Function not implemented.');
// }

