<!-- 
<div class="example-container mat-elevation-z8">
  <div class="closeIcon">
    <button mat-icon-button mat-dialog-close class="mr-2" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="example-table-container">
    <div class="text-center">
      <h3 class="text-white heading" *ngIf="!showURL">VR Course Partner's Location</h3>
      <h5 class="text-white heading" *ngIf="showURL">You will be directed to their website from here, <br/>once you choose the learning partner.</h5>
    </div>

   

    <table mat-table [dataSource]="dataSource"  class="example-table cityTable"
           matSort matSortActive="created" matSortDisableClear matSortDirection="desc" *ngIf="!showURL">

           <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> Sl No </th>
            <td mat-cell *matCellDef="let element;let i = index;"> {{i+1}} </td>
          </ng-container>
        
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef> Partner's City </th>
            <td mat-cell *matCellDef="let element ele_city" (click)="showUrl(element.url)" class="ele_city"> {{element.city}} </td>
          </ng-container>
        

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <table mat-table [dataSource]="partnerSource" class="mat-elevation-z8 mt-3 urlTable" *ngIf="showURL">

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Sl No </th>
        <td mat-cell *matCellDef="let element;let i = index;"> {{i+1}} </td>
      </ng-container>
    
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Partner's Name </th>
        <td mat-cell *matCellDef="let element"> {{element.vendor}} </td>
      </ng-container>
    
    
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef> Partner's URL </th>
        <td mat-cell *matCellDef="let element"> <a class="text-light" href="{{element.url}}" target="_blank">{{element.url}}</a> </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="showColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: showColumns;"></tr>
    </table> 
  </div>

</div> -->

<div class="container-fluid d-flex">
  <h2 class="m-0" fxFlex="initial" *ngIf="!showURL">VR Course Partner's Location</h2>
  <h2 class="text-white heading" *ngIf="showURL">You will be directed to their website from here, <br/>once you choose the learning partner</h2>
  <span class="ml-auto"></span>
  <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
  </button>
</div>

<div  class="example-container">
  <table mat-table [dataSource]="dataSource"  class="mt-3 mb-3 cityTable"
           matSort matSortActive="created" matSortDisableClear matSortDirection="desc" *ngIf="!showURL">

           <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> Sl No </th>
            <td mat-cell *matCellDef="let element;let i = index;"> {{i+1}} </td>
          </ng-container>
        
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef> Partner's City </th>
            <td mat-cell *matCellDef="let element ele_city" (click)="showUrl(element.url)" class="ele_city"> {{element.city}} </td>
          </ng-container>
        

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <table mat-table [dataSource]="partnerSource" class="mt-3 mb-3 urlTable" *ngIf="showURL">

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Sl No </th>
        <td mat-cell *matCellDef="let element;let i = index;"> {{i+1}} </td>
      </ng-container>
    
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Partner's Name </th>
        <td mat-cell *matCellDef="let element"> <a class="text-light" href="{{element.url}}" target="_blank">{{element.vendor}} </a></td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="showColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: showColumns;"></tr>
    </table> 
</div>
