
<div style="display:flex; align-items: center; width: 138px;">
    <ng-container *ngFor="let item of [1,2,3,4,5]">
        <svg [style.margin-right]="space" [style.height]="size" [style.width]="size" viewBox="0 0 24 24">
            <path d="M12 19.288L19.416 24l-1.968-8.88L24 9.145l-8.628-.771L12 0 8.628 8.375 0 9.145l6.552 5.975L4.584 24z"
                fill="var(--dark-disabled)" />
            <path *ngIf="item <= rating" d="M12 19.288L19.416 24l-1.968-8.88L24 9.145l-8.628-.771L12 0 8.628 8.375 0 9.145l6.552 5.975L4.584 24z"
                fill="var(--rating)" />
                <path *ngIf="item > rating && (item - rating) <= 0.5" d="M12 19.288V0L8.628 8.375 0 9.145l6.552 5.975L4.584 24z" fill="var(--rating)" />
        </svg>
    </ng-container>
    <span [style.font-size]="size + 'px'" [style.line-height]="size + 'px'" [style.color]="'#fff'" >{{rating}}</span>
</div>