<section class="header-section bg-background-dark p-4">
  <div class="container-fluid container-lg">
    <h1 class="mat-title text-light my-2">My Profile</h1>
  </div>
</section>

<section class="profile-section">
  <div class="container-fluid container-lg pt-4 px-3 " *ngIf="userForm">
    <form action="" #f="ngForm" [formGroup]="userForm" class=" row  text-light">
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" >
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Title</mat-label>
                <mat-select formControlName="title" required>
                  <mat-option value="Mr">Mr</mat-option>
                  <mat-option value="Ms">Ms</mat-option>
                  <mat-option value="Mrs">Mrs</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName" required>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" required>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Current Password</mat-label>
                <input matInput formControlName="currentPass" type="password" autocomplete="off">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>New Password</mat-label>
                <input matInput formControlName="newPass" type="password"
                  matTooltip="Password must be atleast 8 character in length. &#013; Atleast 1 uppercase (A-Z). &#013; Atleast 1 lowercase (a-z). &#013; Atleast 1 number (0-9).  &#013; Atleast 1 special characters (eg : !@#$%^&*)."
                  matTooltipClass="test">
                <mat-error *ngIf="userForm.controls.newPass.invalid">Please enter a valid password
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="confirmPass" type="password" autocomplete="off">
                <mat-error *ngIf="userForm.controls.confirmPass.invalid">
                  Password do not match
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Phone Number</mat-label>
                <input matInput formControlName="phNo" class="active">
                <span matPrefix>+91&nbsp;</span>
                <mat-error *ngIf="userForm.controls.phNo.invalid">Invalid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company</mat-label>
                <input matInput formControlName="company" class="active">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Address</mat-label>
                <input matInput formControlName="address" class="active">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>City</mat-label>
                <input matInput formControlName="city" [matAutocomplete]="auto" class="active">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="userForm.controls.city.invalid">Invalid city name</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>State/Province</mat-label>
                <input matInput formControlName="state" [matAutocomplete]="autoState" class="active">
                <mat-autocomplete #autoState="matAutocomplete">
                  <mat-option *ngFor="let option of filterStateOptions | async" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="userForm.controls.state.invalid">Invalid state name
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Postcode</mat-label>
                <input matInput formControlName="postcode" minLength="6" class="active">
                <mat-error *ngIf="userForm?.controls.postcode.invalid && userForm?.controls.postcode.touched">
                  Invalid postcode / zipcode</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Country</mat-label>
                <input matInput formControlName="country" class="active">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <input type='file' class="d-none" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
              formControlName="file" (change)="uploadFile($event)" />
            <img src="{{imageUrl}}" alt="" class="imageUpload" #imgURL id="imgURL">
            <button mat-button color="accent" class="mt-5 uploadBtn" (click)="fileInput.click()">Upload
              Image</button><br />
            <button mat-button class="uploadBtn mt-2" *ngIf='imageUrl !== "../../../assets/images/uploadPic.png"'
              (click)="removeUploadedFile()">Remove Image</button>
          </div>
        </div>
      </div>
    </form>
    <div class="submitBtn text-center mb-5">
      <button mat-button class="beforeUpdate" *ngIf="!changeButton" (click)="saveChanges()">Save
        Changes</button>
      <button mat-button class="afterUpdate" *ngIf="changeButton">Changes Saved</button>
    </div>
  </div>
  
</section>