
<div class="container">
    <div class="row open-dialog" >
        <div class="col-sm-12-col-md-12-col-lg-12 col-xs-12">
            
            <button mat-button (click)="startOver()">Start Over</button><br/>
            <button mat-button class="mt-5" (click)="resume()">Resume</button>
           
        </div>
</div>


</div>







