import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, HostBinding } from '@angular/core';
import { MyCartService } from 'src/app/services/my-cart.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WishlistService } from 'src/app/services/wishlist.service';
import { OnlinePaymentService } from 'src/app/services/online-payment.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { SearchFilterService } from 'src/app/services/search-filter.service';

@Component({
  selector: 'app-my-cart',
  templateUrl: './my-cart.component.html',
  styleUrls: ['./my-cart.component.scss']
})
export class MyCartComponent implements OnInit {

  public cart = false;
  public sideMenu = true;
  public categoryDetails: any = [];
  public userName: { userId: any; };
  public databaseUser;
  public CartData;
  public userDetails;


  public cartLength = 0;
  public totalPrice = 0;
  public strikedPrice = 0;
  public discountPercent = 0;
  public getCourse: any = [];
  public isCartLoaded = false;

  @HostBinding('class.flex-fill') autoHeight = true;

  // @Input("cartBadge") cartBadge : any
  constructor(public cartService: MyCartService, public userservice: UserService,
              public wishlist: WishlistService, public router: Router, public snackBar: MatSnackBar,
              public transaction: OnlinePaymentService, public service: CommonServiceService,
              public authservice: AuthService, public filterService: SearchFilterService,) {

  }

  ngOnInit(): void {

    this.userDetails = this.userservice.userDetails.data;
    this.isCartLoaded = false;
    this.cartService.getCartdetails(this.userDetails.userId).subscribe(data => {
      this.cartLength = data.cartData.length;
      this.databaseUser = data.cartData;
      // console.log(this.databaseUser);

      this.databaseUser.forEach((cartData: any) => {
        this.totalPrice += cartData.price;
        this.strikedPrice += cartData.strikedPrice;
        this.discountPercent += Number(cartData.discountPercent);

        this.getCourse.push({
          courseId: cartData.courseId,
          courseName: cartData.courseName,
          coursePrice: cartData.price,
          videoUrl: cartData.videoUrl,
          instructor: cartData.instructor[0],
        });

      });

      this.discountPercent = this.discountPercent / this.databaseUser.length;
      this.isCartLoaded = true;
    });
  }


  removeCart(index, cart) {
    this.totalPrice = 0;
    this.strikedPrice = 0;
    this.getCourse = [];
    // this.databaseUser.splice(index,1)
    const wishlistUser = this.userservice.userDetails.data.userId;
    const wishlistCourse = cart.courseId;
    this.cartService.removeCart(wishlistUser, wishlistCourse).subscribe(() => {
      this.databaseUser.splice(index, 1);
      this.cartService.decreaseCartCount();
      this.cartLength--;
    });
    this.databaseUser.forEach((data) => {
      this.totalPrice += data.price;
      this.strikedPrice += data.strikedPrice;
      this.getCourse.push(data.courseId);
    });
  }

  moveToWishlist(index, cart) {

    const wishlistUser = this.userservice.userDetails.data.userId;
    const wishlistCourse = cart.courseId;

    this.wishlist.addTowishlist(wishlistUser, wishlistCourse).subscribe(data => {
      if (data.success) {
        this.cartService.removeCart(wishlistUser, wishlistCourse).subscribe(() => {
          this.cartLength--;
          this.cartService.decreaseCartCount();
          this.totalPrice = 0;
          this.strikedPrice = 0;
          this.getCourse = [];
          this.databaseUser.splice(index, 1);
          this.databaseUser.forEach((cartData: any) => {
            this.totalPrice += cartData.price;
            this.strikedPrice += cartData.strikedPrice;
            this.getCourse.push(cartData.courseId);
          });
        });
      }
    },
      () => {
        Swal.fire({
          icon: 'info',
          text: 'Item already in wishlist !',
          showConfirmButton: true,
          heightAuto: false,
          allowOutsideClick: false,
        });
      }
    );


  }

  checkout() {
    const details = {
      userEmail: this.userservice.userDetails.data.email,
      //  payment : "Credit Card",
      //  totalCost : this.totalPrice,
      //  courses : this.getCourse,
    };
    const sharedData = {
      totalCost: this.totalPrice,
      courseName: this.getCourse[0].courseName,
      actualPrice: this.strikedPrice,
      discount: this.strikedPrice - this.totalPrice,
      videoUrl: this.getCourse[0].videoUrl,
      instructor: this.databaseUser[0].instructor,
      courseId: this.getCourse[0].courseId,
    };
    const courseDetails = this.getCourse.map(course => {
      return {
        courseName: course.courseName,
        videoUrl: course.videoUrl,
        courseId: course.courseId,
        actualPrice: course.coursePrice,
        instructor: course.instructor
      };
    });
    this.cartService.courseDetails = courseDetails;
    this.cartService.sharedData = sharedData;
    this.transaction.razorpayPayment(details,this.cartService.sharedData.totalCost).subscribe(data => {
      if (data.success) {
        this.router.navigate(['layout/online-payment']);
        this.transaction.orderId = data.order;
      }

    });

  }

  clickCourse(data){
    this.router.navigate(['/course-details'], { queryParams: { courseId: data.courseId } });
  }


}
