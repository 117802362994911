import { Injectable, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import * as firebase from 'firebase';
import { Observable, of } from 'rxjs';
import { ConditionalExpr } from '@angular/compiler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from './user.service';
import Swal from 'sweetalert2';
import { error } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit {
  public user: Observable<firebase.User>;
  public userDetails = [];
  public error: any;
  public emailUsers: any;
  public verifiedEmail;
  public seconds: any = 60;
  public timer;
  public displayText = 'Try again in #1 seconds';
  public setTimer;
  constructor(public afAuth: AngularFireAuth, public ngZone: NgZone, public router: Router, public snackBar: MatSnackBar, private userService: UserService) {
    this.user = afAuth.authState;
    this.afAuth.authState.subscribe((data) => {
      if (data) {
        localStorage.setItem('user', JSON.stringify(data));
      }
    });
  }

  handleVerifyEmail(actionCode) {
    return this.afAuth
      .applyActionCode(actionCode)
      .then((result) => {})
      .catch((err) => {});
  }

  handleResetPassword(actionCode) {
    return this.afAuth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        const accountEmail = email;
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          text: error,
          showConfirmButton: true,
        });
      });
  }

  async getIdToken() {
    const idToken = await (await this.afAuth.currentUser).getIdToken(true);
    return idToken;
  }

  async loginWithGoogle() {
    const login = await this.afAuth.signInWithPopup(new auth.GoogleAuthProvider());
    return login;
  }

  async signupWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const popup = this.afAuth.signInWithPopup(provider);
    return popup;
  }

  async signupWithFacebook() {
    const FacebookAuth = new firebase.auth.FacebookAuthProvider();
    const popup = this.afAuth.signInWithPopup(FacebookAuth);
    return popup;
  }

  async loginWithFacebook() {
    const fbLogin = await this.afAuth.signInWithPopup(new auth.FacebookAuthProvider());
    return fbLogin;
  }

  async emailSignUp(user) {
    return this.afAuth
      .createUserWithEmailAndPassword(user.userEmail, user.userPassword)
      .then((result) => {
        this.verifiedEmail = result;
        if (result.user.emailVerified !== true) {
          this.SendVerificationMail();
          Swal.fire({
            title: 'We have mailed you!',
            icon: 'success',
            html:
              "<div style='font-size:14px;'>We have sent an email to" +
              ' ' +
              '<b>' +
              result.user.email +
              '</b></div>' +
              "<div style='margin-top:20px;text-align:justify;font-size:14px;'> <br> Please click on the link given in the email to verify your account. If you have not received the verification email, please check your 'Spam' or 'Bulk Email' folder. If you still can't find the mail, click the resend button below to have another email sent to you.</div>",
            showCloseButton: true,
            cancelButtonText: 'Done',
            confirmButtonText: 'Resend verification e-mail',
            allowOutsideClick: false,
            heightAuto: false,
            footer: this.displayText.replace(/#1/, this.seconds),
            customClass: {
              icon: 'swal-icon',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.SendVerificationMail();
              Swal.fire({
                icon: 'success',
                text: 'We have re-sent the account verification link to your email address. Please click on the link given in the email to verify your account.',
                heightAuto: false,
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate(['email-login']);
                }
              });
            }
          });
          let x = document.getElementsByClassName('swal2-footer');
          let y = document.getElementsByClassName('swal2-confirm');
          y[0].setAttribute('disabled', 'disabled');
          this.setTimer = setInterval(() => {
            this.seconds--;
            if (this.seconds <= 0) {
              y[0].removeAttribute('disabled');
              x[0].innerHTML = ' ';
              clearInterval(this.setTimer);
            } else {
              x[0].innerHTML = this.displayText.replace(/#1/, this.seconds);
            }
          }, 600);
        } else {
          this.ngZone.run(() => {
            this.router.navigate(['']);
          });
        }
      })
      .catch((error) => {
        // window.alert(error.message)
        Swal.fire({
          icon: 'error',
          text: error.message,
          showConfirmButton: true,
          heightAuto: false,
        });
      });
  }

  async getemailIdToken() {
    const idtoken = await (await this.afAuth.currentUser).getIdToken(true);
    return idtoken;
  }

  async emailSignIn(user) {
    let login = await this.afAuth.signInWithEmailAndPassword(user.userEmail, user.userPassword);
    return login;
    // .then(result=>{
    //   console.log(result)
    //   if(result.user.emailVerified !== true){
    //     // this.SendVerificationMail();
    //     Swal.fire({
    //       text: 'Please validate your email address. Kindly check your inbox ',
    //       showConfirmButton: true,
    //       heightAuto: false,
    //     })
    //   }
    //   else{
    //     this.ngZone.run(()=>{
    //       this.router.navigate(['layout/dashboard']);
    //     });
    //   }
    //   // this.SetUserData(result.user)
    // }).catch(error =>{
    //   Swal.fire({
    //     icon : 'error',
    //     text: error.message,
    //     showConfirmButton: true,
    //     heightAuto: false,
    //   })
    // })
  }

  changePassword(email, oldpassword, newpassword, userId, userDetails) {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, oldpassword)
      .then(function (userCredential) {
        userCredential.user.updatePassword(newpassword);
        return {
          userCredential,
          success: true,
        };
        // this.userService.updateUserProfile(userId,userDetails)
      })
      .catch((err) => {
        return {
          err,
          success: false,
        };
      });
  }

  SendVerificationMail() {
    return firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then((result) => {
        // this.router.navigate(['layout/dashboard']);
      });
  }

  sendPasswordResetEmail(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  confirmPasswordReset(code, password) {
    return this.afAuth.confirmPasswordReset(code, password);
  }

  async logout() {
    await this.afAuth.signOut();
    // localStorage.removeItem('token');
    this.router.navigate(['/login']);
    // localStorage.removeItem('Refreshtoken');
  }

  async logoutafter() {
    await this.afAuth.signOut();
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
    localStorage.removeItem('Refreshtoken');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getRefreshToken(): Observable<string> {
    let refreshToken = localStorage.getItem('Refreshtoken');
    return of(refreshToken);
  }

  deleteToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('Refreshtoken');
  }

  getUserPayload() {
    const token = localStorage.getItem('token');
    if (token) {
      const userPayload = atob(token.split('.')[1]);
      console.log(userPayload, 'userPayload');
      return JSON.parse(userPayload);
    } else {
      return null;
    }
  }

  isLoggedIn() {
    const userPayload = this.getUserPayload();
    console.log(userPayload, 'userPayload1');

    if (userPayload) {
      if (userPayload.data) {
        // if(userPayload.exp > Date.now() / 1000) {
        // console.log(userPayload);

        if (userPayload.data.firstName) {
          this.userService.updateUserDetails(userPayload);
          return true;
        } else {
          this.deleteToken();

          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // get isLoggedIn(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user'));return (user !== null) ? true : false;}

  ngOnInit(): void {
    this.afAuth.authState.subscribe((data) => {
      if (data) {
        localStorage.setItem('user', JSON.stringify(data));
      }
    });
  }
}
