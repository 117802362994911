import { Overlay } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseVideosComponent } from 'src/app/course/course-videos/course-videos.component';


@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  public sharedDetails : any;
  
  constructor(public dialogRef: MatDialogRef<CourseVideosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public overlay: Overlay) {
    this.sharedDetails = data;
   }

  
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(){
    this.dialogRef.close(true);
  }

  


  ngOnInit(): void {
  }

}
