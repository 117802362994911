import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ViewAllCoursesComponent } from './homePageComponents/view-all-courses/view-all-courses.component';
// import { TestmonialsComponent } from './homePageComponents/testmonials/testmonials.component';
import {CommonServiceService} from './services/common-service.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Compiler, COMPILER_OPTIONS, CompilerFactory} from '@angular/core';
import {JitCompilerFactory} from '@angular/platform-browser-dynamic';
export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}
import {MatCardModule} from '@angular/material/card';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignUPComponent } from './sign-up/sign-up.component';
import { LoginComponent } from './login/login.component';
// import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
// import { SideBarComponent } from './side-bar/side-bar.component';
import { LayoutModule } from './layout/layout.module';
import { MaterialModule } from './material.module';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AuthService } from './services/auth.service';
import { EmailSignupComponent } from './email-signup/email-signup.component';
import { EmailLoginComponent } from './email-login/email-login.component';
import { SharedModule } from './shared.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ConfirmPasswordResetComponent } from './confirm-password-reset/confirm-password-reset.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { JwtInterceptor } from './shared/helper/jwt.interceptor';

// import { FlexLayoutModule} from '@angular/flex-layout';


@NgModule({
  declarations: [
    AppComponent,
    // ViewAllCoursesComponent,
    // TestmonialsComponent,
    SignUPComponent,
    LoginComponent,
    EmailSignupComponent,
    EmailLoginComponent,
    ForgetPasswordComponent,
    ConfirmPasswordResetComponent,
    EmailVerificationComponent,
    UserManagementComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    // tslint:disable-next-line: deprecation
    HttpModule,
    CarouselModule,
    HttpClientModule,
    MatCardModule,
    NgbModule,
    LayoutModule,
    MaterialModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [
    AppComponent,
  ],

  providers: [ CommonServiceService, AuthService,
    {provide: COMPILER_OPTIONS, useValue: {}, multi: true},
    {provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS]},
    {provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory]},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
