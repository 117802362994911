import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { MyCartService } from 'src/app/services/my-cart.service';
import { OnlinePaymentService } from 'src/app/services/online-payment.service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { UserService } from 'src/app/services/user.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transaction-success',
  templateUrl: './transaction-success.component.html',
  styleUrls: ['./transaction-success.component.scss']
})
export class TransactionSuccessComponent implements OnInit {
  public sharedDetails;
  public cartDetails = [];

  @HostBinding('class.flex-fill') autoHeight = true;

  constructor(public cartService: MyCartService, private router: Router) {}

  ngOnInit(): void {

    if (this.cartService.sharedData === undefined) {
      this.router.navigate(['layout/transaction-success']);
    } else {
      this.sharedDetails = this.cartService.sharedData;
      this.cartDetails.push(this.cartService.courseDetails);
    }
  }

}
